import React from 'react'
import { createRoot } from 'react-dom/client'
import { useNavigate } from 'react-router-dom'
import App from './App'
import { AppRoot } from '@telegram-apps/telegram-ui'
import ErrorPage from './pages/ErrorPage'
import CurrentHw from './pages/CurrentHw'
import Answer from './pages/Answer'
import Archive from './pages/Archive'
import HwHeader from './components/HwHeader'
import Footer from './components/Footer'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import GroupSet from './pages/GroupSet'
import Welcome from './pages/Welcome'
import { themeParams } from '@telegram-apps/sdk'
import { init as sdkInit, retrieveLaunchParams } from '@telegram-apps/sdk'
import { init, backButton } from '@telegram-apps/sdk-react'
import { DataProvider } from './components/DataProvider'

function initTelegramEnvironment() {
  sdkInit()
  init()
  themeParams.mount()
  themeParams.bindCssVars()
  backButton.mount()
  backButton.onClick(() => {
    console.log(location.pathname)
    history.back()
  })

  if (window.Telegram.WebApp.colorScheme === 'dark') {
    document.querySelector('body').classList.add('darkBody')
  }

  const bgColor = themeParams.backgroundColor()
  const newBgColor = bgColor + 'aa'
  document.documentElement.style.setProperty(
    '--bg-color-with-opacity',
    newBgColor
  )
}

initTelegramEnvironment()

const { initDataRaw, initData } = retrieveLaunchParams()

const UserDataContext = React.createContext()
const container = document.getElementById('root')
const root = createRoot(container)
const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AppRoot>
        <DataProvider>
          <UserDataContext.Provider value={initData.user}>
            <Welcome />
          </UserDataContext.Provider>
        </DataProvider>
      </AppRoot>
    ),
  },
  {
    path: '/Group',
    element: (
      <AppRoot>
        <DataProvider>
          <Footer />
        </DataProvider>
      </AppRoot>
    ),
    children: [
      {
        path: '/Group',
        element: (
          <UserDataContext.Provider value={initData.user}>
            <App />
          </UserDataContext.Provider>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: '/Group/GroupSet',
        element: (
          <UserDataContext.Provider value={initData.user}>
            <GroupSet />
          </UserDataContext.Provider>
        ),
      },
      {
        path: '',
        element: (
          <UserDataContext.Provider>
            <HwHeader />
          </UserDataContext.Provider>
        ),
        children: [
          {
            index: true,
            path: '/Group/Homework/:articleIndex/:hometaskIndex',
            element: <CurrentHw />,
          },
          {
            index: false,
            path: '/Group/Archive/:articleIndex',
            element: <Archive />,
          },
          {
            index: false,
            path: '/Group/Archive/:articleIndex/ArchiveHomework/:hometaskIndex',
            element: <CurrentHw />,
          },
        ],
      },
      {
        path: '/Group/Homework/:articleIndex/:hometaskIndex/Answer/:solutionIndex',
        element: (
          <UserDataContext.Provider>
            <Answer />
          </UserDataContext.Provider>
        ),
      },
    ],
  },
])

root.render(
  <div>
    <RouterProvider router={router} />
  </div>
)

export { UserDataContext }
