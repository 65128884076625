import React, { useEffect, useRef } from 'react'

export default function Modal({ children, isOpen, onClose }) {
  const overlay = useRef(null)
  const modal = useRef(null)

  useEffect(() => {
    overlay.current.addEventListener('click', function () {
      onClose()
    })
  }, [])

  useEffect(() => {
    if (isOpen) {
      modal.current.classList.add('show')
      overlay.current.classList.add('show')
    } else {
      modal.current.classList.remove('show')
      overlay.current.classList.remove('show')
    }
  }, [isOpen])

  return (
    <div>
      <div ref={overlay} className='modalOverlay'></div>
      <div ref={modal} className='modalComponent'>
        {children}
      </div>
    </div>
  )
}
