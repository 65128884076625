import React, { useContext } from 'react'
import { NavLink, Outlet, useParams } from 'react-router-dom'
import { DataContext } from './DataProvider'

function HwHeader() {
  const { articleIndex } = useParams()
  const { articles } = useContext(DataContext)
  const currentArticle = articles[articleIndex]
  const lessonName = currentArticle.title
  const homeworkPath = `Homework/${articleIndex}/0`
  const archivePath = `Archive/${articleIndex}`

  return (
    <div className='hometaskPage'>
      <header>
        <h1 className='title'>{lessonName}</h1>

        <div className='nav'>
          <NavLink to={homeworkPath} className='navButton'>
            Домашнее задание
          </NavLink>
          <NavLink to={archivePath} className='navButton'>
            Архив
          </NavLink>
        </div>
      </header>
      <Outlet />
    </div>
  )
}

export default HwHeader
