import React from 'react'
import { FaPen } from 'react-icons/fa'

class Version extends React.Component {
  render() {
    return (
      <div className='version'>
        <p>{process.env.REACT_APP_VERSION}</p>
      </div>
    )
  }
}

export default Version
