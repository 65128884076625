import React, { useContext, useEffect, useState } from 'react'
import Lesson from '../components/Lesson'
import LessonsHeader from '../components/LessonsHeader'
import { DataContext } from '../components/DataProvider'
import { Spinner } from '@telegram-apps/telegram-ui'

function MainLessons() {
  const [loading, setLoading] = useState(true)
  const { group, articles, setCurrentHometasks, hometasks } =
    useContext(DataContext)

  useEffect(() => {
    const fetchData = async () => {
      await setCurrentHometasks()
      setLoading(false)
    }

    if (articles.length > 0) {
      fetchData()
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <div className='mainLessons'>
      <LessonsHeader groupName={group.group_name} />
      {loading ? (
        <Spinner size='m' className='loadSpinner' />
      ) : articles.length > 0 ? (
        articles.map((article, index) => (
          <Lesson
            key={index}
            articleIndex={index}
            lessonName={article.title}
            deadline={hometasks[index][0] ? hometasks[index][0].deadline : ''}
          />
        ))
      ) : (
        <h6 className='noLessons'>В этой группе пока нет предметов!</h6>
      )}
    </div>
  )
}

export default MainLessons
