import React from 'react'
import { FaChevronRight } from 'react-icons/fa6'

function GroupButton(props) {
  return (
    <div className='groupButton' onClick={props.onClick}>
      <div className='text'>
        <p className='title'>{props.title}</p>
        <p className='description'>{props.description}</p>
      </div>
      <FaChevronRight className='icon' />
    </div>
  )
}

export default GroupButton
