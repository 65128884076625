import React from 'react'
import { useParams } from 'react-router-dom'
import { BsStar } from 'react-icons/bs'
import { Link } from 'react-router-dom'

function User({ solution, solutionIndex }) {
  const { articleIndex, hometaskIndex } = useParams()
  const userData = solution.userData
  const path = `/Group/Homework/${articleIndex}/${hometaskIndex}/Answer/${solutionIndex}`

  return (
    <div className='user'>
      <Link className='userLink' to={path}>
        <div className='user_info'>
          <img
            src={userData.photo_url}
            className='user_photo'
            alt='https://yandex.ru/images/search?text=картинка+пустая+аватарка&pos=0&rpt=simage&img_url=https%3A%2F%2Fsh-vmulebkinskaya-r82.gosweb.gosuslugi.ru%2Fnetcat_files%2F9%2F148%2F1_4.jpg&from=tabbar&lr=213'
          />
          <p className='user_name'>
            {userData.first_name} {userData.last_name}
          </p>
        </div>
        <div className='scoreWrap'>
          <BsStar className='scoreIcon' />
          <p className='scoreValue'>0</p>
        </div>
      </Link>
    </div>
  )
}

export default User
