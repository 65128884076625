import React, { useContext, useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import GroupButton from '../components/GroupButton'
import GroupListButton from '../components/GroupListButton'
import GroupLesson from '../components/GroupLesson'
import Modal from '../components/Modal'
import '@telegram-apps/telegram-ui/dist/styles.css'
import { Input, Button, AppRoot } from '@telegram-apps/telegram-ui'
import BackButton from '../components/BackButton'
import { DataContext } from '../components/DataProvider'
import { Sheet } from 'react-modal-sheet'
import { styled } from 'styled-components'

const CustomSheet = styled(Sheet)`
  .react-modal-sheet-container {
    background-color: var(--tg-theme-secondary-bg-color);
  }
  .react-modal-sheet-header {
    background-color: var(--tg-theme-secondary-bg-color);
  }
`

function GroupSet() {
  const navigate = useNavigate()
  const [inputTitle, setInputTitle] = useState('')
  const [inputStatus, setInputStatus] = useState('focused')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState('')
  const [deletingModalIsOpen, setDeletingModalIsOpen] = useState(false)
  const [leavingConfirmationIsOpen, setLeavingConfirmationIsOpen] =
    useState(false)
  const [currentDeletingId, setCurrentDeletingId] = useState(0)
  const {
    articles,
    deleteLesson,
    updateData,
    postLesson,
    deleteFromGroup,
    group,
    deleteUser,
  } = useContext(DataContext)

  const handleOutsideClick = (event) => {
    if (
      document.querySelector('.react-modal-sheet-content') &&
      !document
        .querySelector('.react-modal-sheet-content')
        .contains(event.target)
    ) {
      closeModal()
    }
  }

  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
    setInputStatus('focused')
    setErrorSubmit('')
  }

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (modalRef.current && !modalRef.current.contains(event.target)) {
  //       closeModal()
  //     }
  //   }
  // }, [modalIsOpen, onClose])

  const openDeleting = () => setDeletingModalIsOpen(true)
  const closeDeleting = () => setDeletingModalIsOpen(false)

  const openLeaving = () => setLeavingConfirmationIsOpen(true)
  const closeConfirmation = () => setLeavingConfirmationIsOpen(false)

  const confirmDeleting = async () => {
    await deleteLesson(currentDeletingId)
    setCurrentDeletingId(0)
    closeDeleting()
  }

  const buttonName1 = 'Сменить группу'
  const buttonDesc1 = 'Создать или присоединиться к новой группе'
  const buttonName2 = 'Добавить предмет'
  const buttonDesc2 = 'Добавить новый предмет в группу'
  const buttonName3 = 'Удалить предмет'
  const buttonDesc3 = 'Удалить существующий предмет'
  const buttons = true ? (
    <div className='buttons'>
      <GroupButton
        title={buttonName1}
        description={buttonDesc1}
        onClick={() => {
          openLeaving()
        }}
      />
      <GroupButton
        title={'Копировать ссылку'}
        description={'Копировать пригласительную ссылку на группу'}
        onClick={async () => {
          try {
            await navigator.clipboard.writeText(group.invite_link)
            console.log('Текст успешно скопирован в буфер обмена!')
          } catch (err) {
            console.error('Ошибка:', err)
          }
        }}
      />
      <GroupButton
        title={buttonName2}
        description={buttonDesc2}
        onClick={openModal}
      />

      <GroupListButton
        title={buttonName3}
        description={buttonDesc3}
        onClick={showLessonList}
      />
    </div>
  ) : (
    <div>
      <GroupButton title={buttonName1} description={buttonDesc1} />
    </div>
  )

  const lessonsList = (
    <div className='lessonListWrap'>
      <div className='lessonsList hide'>
        {articles.map((el) => (
          <GroupLesson
            lesson={el}
            onClick={openDeleting}
            setCurrentDeletingId={setCurrentDeletingId}
          />
        ))}
      </div>
    </div>
  )

  function showLessonList() {
    document
      .querySelector('.groupListButton')
      .querySelector('.icon')
      .classList.toggle('reverseIcon')
    document
      .querySelector('.groupSet')
      .querySelector('.lessonsList')
      .classList.toggle('hide')
  }

  const handleChange = (event) => {
    event.preventDefault()
    setInputTitle(event.target.value.trim())
  }

  const handleAddLesson = async (event) => {
    event.preventDefault()
    const isValid = inputTitle.length <= 50 && inputTitle.length >= 1

    if (isValid) {
      await postLesson(inputTitle)
      setModalIsOpen(false)
    } else {
      if (inputTitle.length < 1) {
        setErrorSubmit('Введите название группы')
      } else {
        setErrorSubmit('Введите название длиной не более 50 символов')
      }
      setInputStatus('error')
      setModalIsOpen(true)
    }
  }

  const handleLeaving = async () => {
    await deleteFromGroup()
    await deleteUser()
    navigate('/')
  }

  return (
    <div className='groupSet'>
      <BackButton />
      <h4>Управление группой</h4>
      {buttons}
      {lessonsList}
      <CustomSheet
        isOpen={modalIsOpen}
        onClose={closeModal}
        className={'modal sheet'}
        onClick={handleOutsideClick}
        detent='content-height'
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <AppRoot>
              <div className='lessonForm'>
                <form onSubmit={handleAddLesson}>
                  <Input
                    className='inputLessonTitle'
                    onFocus={() => {
                      setInputStatus('focused')
                      setErrorSubmit('')
                    }}
                    onChange={handleChange}
                    status={inputStatus}
                    header='Новый предмет'
                    placeholder='Введите название...'
                  />
                  <p
                    style={{ margin: errorSubmit === '' ? '0' : '10px 0' }}
                    className='errorText'
                  >
                    {errorSubmit}
                  </p>
                  <div className='buttons'>
                    {/* <Button
                  onClick={closeModal}
                  className='cancelLeaving'
                  mode='plain'
                  size='s'
                >
                  Отмена
                </Button> */}
                    <Button
                      type='submit'
                      className='addNewLesson'
                      mode='filled'
                      size='s'
                    >
                      Добавить
                    </Button>
                  </div>
                </form>
              </div>
            </AppRoot>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </CustomSheet>
      <Modal
        isOpen={leavingConfirmationIsOpen}
        onClose={closeConfirmation}
        className={'modal'}
      >
        <AppRoot>
          <div className='leavingConfirmation'>
            <p className='leavingTitle'>Выйти из группы?</p>
            <p className='leavingText'>
              Для захода обратно понадобится снова ввести ссылку
            </p>
            <div className='buttons'>
              <Button
                onClick={closeConfirmation}
                className='cancelLeaving'
                mode='plain'
                size='s'
              >
                Отмена
              </Button>
              <Button
                onClick={() => {
                  handleLeaving()
                }}
                className='confirmLeaving'
                mode='plain'
                size='s'
                style={{ color: 'red' }}
              >
                Покинуть группу
              </Button>
            </div>
          </div>
        </AppRoot>
      </Modal>
      <Modal
        isOpen={deletingModalIsOpen}
        onClose={closeDeleting}
        className={'modal'}
      >
        <AppRoot>
          <div className='leavingConfirmation'>
            <p className='leavingTitle'>Удалить предмет?</p>
            <p className='leavingText'>
              Все домашние задания по предмету будут безвозвратно удалены
            </p>
            <div className='buttons'>
              <Button
                onClick={closeDeleting}
                className='cancelLeaving'
                mode='plain'
                size='s'
              >
                Отмена
              </Button>
              <Button
                onClick={confirmDeleting}
                className='confirmLeaving'
                mode='plain'
                size='s'
                style={{ color: 'red' }}
              >
                Удалить предмет
              </Button>
            </div>
          </div>
        </AppRoot>
      </Modal>
    </div>
  )
}

export default GroupSet
