import React from 'react'
import './styles/homework.css'
import './styles/header.css'
import './styles/user.css'
import './styles/lesson.css'
import './styles/footer.css'
import './styles/archive.css'
import './styles/answer.css'
import './styles/comment.css'
import './styles/inputComment.css'
import './styles/groupButton.css'
import './styles/groupSet.css'
import './styles/version.css'
import './styles/welcome.css'
import './styles/currentHomework.css'
import './styles/mainLessons.css'
import '@telegram-apps/telegram-ui/dist/styles.css'
import './styles/darkTheme.css'
import './styles/common.css'
import './styles/modal.css'
import MainLessons from './pages/MainLessons'

function App() {
  return (
    <div>
      <MainLessons />
    </div>
  )
}

export default App
