import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { BsStar } from 'react-icons/bs'
import { FaFilePdf, FaFileWord, FaFileExcel } from 'react-icons/fa'
import { BsStarFill } from 'react-icons/bs'
import Comment from '../components/Comment'
import GalleryCarousel from 'react-gallery-carousel'
import 'react-gallery-carousel/dist/index.css'
import BackButton from '../components/BackButton'
import Homework from '../components/Homework'
import Modal from '../components/Modal'
import ReactRating from 'react-rating'
import InputComment from '../components/InputComment'
import { DataContext } from '../components/DataProvider'

const getIcon = (fileType) => {
  switch (fileType) {
    case 'pdf':
      return <FaFilePdf className='linkIcon' />
    case 'doc':
    case 'docx':
      return <FaFileWord className='linkIcon' />
    case 'xls':
    case 'xlsx':
      return <FaFileExcel className='linkIcon' />
    default:
      return null
  }
}

function Answer() {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [rating, setRating] = useState(1)

  const openModal = () => setModalIsOpen(true)
  const closeModal = () => setModalIsOpen(false)

  const { articleIndex, hometaskIndex, solutionIndex } = useParams()
  const { hometasks } = useContext(DataContext)
  const hometask = hometasks[articleIndex][hometaskIndex]
  const solution = hometask.solutions[solutionIndex]
  const userData = solution.userData

  const filledScore = 1

  return (
    <div className='answer'>
      <BackButton />
      <div className='profile'>
        <img src={userData.photo_url} alt='' />
        <p>
          {userData.first_name} {userData.last_name}
        </p>
      </div>

      <Homework
        deadline={hometask ? hometask.deadline : ''}
        writtenDescription={hometask ? hometask.written_description : ''}
        oralDescription={hometask ? hometask.oral_description : ''}
        withSettings={false}
        withArrow={false}
      />

      <div className='textScore'>
        <p className='textAnswer'>Ответ к заданию:</p>
        <div className='stars' onClick={openModal}>
          {[...Array(filledScore)].map(() => (
            <BsStarFill className='star' />
          ))}
          {[...Array(5 - filledScore)].map(() => (
            <BsStar className='star' />
          ))}
          {/* <p className='score'>{answerUser.score}</p> */}
        </div>
      </div>
      <hr />
      <div className='files'>
        {/* <div className='carousel-container'>
          <GalleryCarousel
            className='carousel'
            images={solution.file_path}
            hasThumbnails={true}
            hasIndexBoard={true}
            hasMediaButton={true}
            hasSizeButton={true}
            canAutoPlay={false}
            isAutoPlaying={false}
          />
        </div> */}

        {/* {answerUser.answer.files.map((el) => (
          <a href={el.link}>{getIcon(el.fileType)}</a>
        ))} */}
        {/* <a href={solution.file_path}>{getIcon(solution.file_path.fileType)}</a> */}
      </div>
      <hr />

      <Modal isOpen={modalIsOpen} onClose={closeModal}>
        <p className='modalText'>Текущая оценка</p>
        <span className='modalScore'>{filledScore}</span>
        {[...Array(filledScore)].map(() => (
          <BsStarFill className='star' />
        ))}
        {[...Array(5 - filledScore)].map(() => (
          <BsStar className='star' />
        ))}

        <div className='chooseScore'>
          <p>Оцените ответ</p>
          <ReactRating
            className='reactRating'
            initialRating={rating}
            quiet={true}
            start={0}
            stop={5}
            emptySymbol={<BsStar className='ratingStar' />}
            fullSymbol={<BsStarFill className='ratingStar' />}
            onChange={(value) => {
              setRating(value)
            }}
          />
          <button onClick={closeModal}>Сохранить</button>
        </div>
      </Modal>

      {/* <div className='comments'>
        <div className='commentsTitle'>
          <p className='commentsText'>Комментарии</p>
          <p className='commentsCount'> {answerUser.answer.comments.length}</p>
        </div>

        <div className='commentsList'>
          {answerUser.answer.comments.map((el) => (
            <Comment
              key={el.userId}
              user={users[el.userId - 1]}
              value={el.value}
            />
          ))}
          <div className='addComment'>
            <InputComment placeholder='Добавить комментарий...' />
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Answer
