import React from 'react'
import { MdSpeakerNotes } from 'react-icons/md'

class WritingHw extends React.Component {
  render() {
    return (
      <div className='speakHw'>
        <MdSpeakerNotes className='speakIcon' />
        <div className='homeworkText'>
          <p>{this.props.text}</p>
        </div>
      </div>
    )
  }
}

export default WritingHw
