import React, { useState, useContext, useEffect } from 'react'
import Modal from '../components/Modal'
import { AppRoot } from '@telegram-apps/telegram-ui'
import { Input, Button } from '@telegram-apps/telegram-ui'
import '@telegram-apps/telegram-ui/dist/styles.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { DataContext } from '../components/DataProvider'
import { Spinner } from '@telegram-apps/telegram-ui'
import { UserDataContext } from '../index'

function Welcome() {
  const navigate = useNavigate()
  const {
    setData,
    postGroup,
    postUser,
    getGroupByLink,
    getGroupByUserID,
    userData,
    postUserToGroup,
  } = useContext(DataContext)

  const [isModalOpen, setModalOpen] = useState(false)
  const [isLinkModalOpen, setLinkModalOpen] = useState(false)
  const [inputStatus, setInputStatus] = useState('default')
  const [errorSubmit, setErrorSubmit] = useState('')
  const [errorFindGroup, setErrorFindGroup] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [findGroupInputStatus, setFindGroupInputStatus] = useState('focused')
  const [isLoading, setLoading] = useState(true)

  const userDataObject = {
    first_name: userData.firstName,
    last_name: userData.lastName,
    id: userData.id,
    photo_url: userData.photoUrl,
    username: userData.username,
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getGroupByUserID()
        const groupID = response.groupID
        if (groupID) {
          await setData(groupID)
          navigate('/Group')
        } else {
          console.log('Группа пользователя не найдена')
        }
      } catch (error) {
        console.log('Ошибка при получении группы:', error)
      }

      setLoading(false)
    }

    fetchData()
  }, [])

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)
  const openLinkModal = () => setLinkModalOpen(true)
  const closeLinkModal = () => setLinkModalOpen(false)

  const handleChange = (event) => {
    setInputValue(event.target.value.trim())
  }

  const handleFindGroup = async (event) => {
    event.preventDefault()

    if (inputValue.trim().length == 0) {
      setErrorFindGroup('Введите ссылку')
      setFindGroupInputStatus('error')
      setLinkModalOpen(true)
      return
    }

    let groupID = 0

    try {
      groupID = await getGroupByLink(inputValue)
      await postUser(userDataObject)
      await postUserToGroup(groupID)
      await setData(groupID)
      navigate('/Group')
    } catch (error) {
      console.error('Error getting groupByLink: ', error)
      setErrorFindGroup('Группа не найдена')
      setFindGroupInputStatus('error')
      setLinkModalOpen(true)
      return
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const isValid = /^[^\s]{1,15}$/.test(inputValue)
    const isThere = inputValue.length != 0

    if (isValid) {
      await postUser(userDataObject)
      const groupID = await postGroup(inputValue)
      await setData(groupID)
      navigate('/Group')
    } else {
      if (!isThere) {
        setErrorSubmit('Введите название для группы')
      } else {
        setErrorSubmit('Введите одно слово длиной не более 15 символов')
      }
      setInputStatus('error')
      setModalOpen(true)
    }
  }

  if (window.Telegram.WebApp.colorScheme === 'dark') {
    document.querySelector('body').classList.add('darkBody')
  }
  return (
    <div className='welcome'>
      <AppRoot>
        {isLoading ? (
          <Spinner size='m' className='loadSpinner' />
        ) : (
          <div>
            <h3>Привет!</h3>
            <p>Найди свою группу по ссылке или создай свою собственную</p>
            <div className='buttons'>
              <Button
                className='welcomeButton'
                mode='plain'
                size='s'
                onClick={openModal}
              >
                Создать группу
              </Button>
              <Button
                className='welcomeButton'
                mode='plain'
                size='s'
                onClick={openLinkModal}
              >
                Присоединиться
              </Button>
            </div>
          </div>
        )}
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <AppRoot>
            <form onSubmit={handleSubmit}>
              <Input
                onFocus={() => {
                  setInputStatus('focused')
                  setErrorSubmit('')
                }}
                onChange={handleChange}
                status={inputStatus}
                header='Новая группа'
                placeholder='Введите название...'
              />
              <p className='errorText'>{errorSubmit}</p>
              <Button
                type='submit'
                className='createGroupButton'
                mode='filled'
                size='s'
              >
                Создать группу
              </Button>
            </form>
          </AppRoot>
        </Modal>
        <Modal isOpen={isLinkModalOpen} onClose={closeLinkModal}>
          <AppRoot>
            <form onSubmit={handleFindGroup}>
              <Input
                onFocus={() => {
                  setFindGroupInputStatus('focused')
                  setErrorFindGroup('')
                }}
                onChange={handleChange}
                status={findGroupInputStatus}
                header='Ссылка на группу'
                placeholder='Введите ссылку...'
              />
              <p className='errorText'>{errorFindGroup}</p>
              <Button
                type='submit'
                className='createGroupButton'
                mode='filled'
                size='s'
              >
                Найти группу
              </Button>
            </form>
          </AppRoot>
        </Modal>
      </AppRoot>
    </div>
  )
}

export default Welcome
