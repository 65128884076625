import React from 'react'
import { useEffect } from 'react'
import { backButton, useSignal } from '@telegram-apps/sdk-react'
import { useLocation } from 'react-router-dom'

export default function BackButton(props) {
  const isVisible = useSignal(backButton.isVisible)
  const location = useLocation()

  useEffect(() => {
    backButton.show()

    return () => {
      backButton.hide()
    }
  }, [])

  return null
}
