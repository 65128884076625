import React, { useContext, useEffect, useState } from 'react'
import User from '../components/User'
import Homework from '../components/Homework'
import { useParams } from 'react-router-dom'
import BackButton from '../components/BackButton'
import Modal from '../components/Modal'
import { AppRoot, Textarea, Button } from '@telegram-apps/telegram-ui'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { DataContext } from '../components/DataProvider'
import { Spinner } from '@telegram-apps/telegram-ui'
import HomeworkSet from '../components/HomeworkSet'

const theme = document.querySelector('.darkBody')
  ? createTheme({
      palette: {
        mode: 'dark',
      },
    })
  : createTheme({
      palette: {
        mode: 'light',
      },
    })

function CurrentHw() {
  const [isModalOpen, setModalOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [isSettingsOpen, openSettings] = useState(false)
  const [learningHomework, setLearningHomework] = useState('')
  const [writingHomework, setWritingHomework] = useState('')
  const [textarea1Status, setTextarea1Status] = useState('focused')
  const [textarea2Status, setTextarea2Status] = useState('focused')
  const [dateValue, setDateValue] = useState(null)
  const [errorText, setErrorText] = useState('')

  const {
    hometasks,
    postHometask,
    updateHometask,
    articles,
    setHometaskSolutions,
  } = useContext(DataContext)
  const { articleIndex, hometaskIndex } = useParams()

  useEffect(() => {
    const setHometasks = async () => {
      if (hometasks[articleIndex].length != 0) {
        await setHometaskSolutions(articleIndex, hometaskIndex)
      }
      setLoading(false)
    }

    setHometasks()
  }, [hometasks, articleIndex, hometaskIndex])

  const hometask = hometasks[articleIndex][hometaskIndex]
  const solutions = hometask && hometask.solutions

  const handleLearningChange = (event) => {
    event.preventDefault()
    setLearningHomework(event.target.value.trim())
  }
  const handleWritingChange = (event) => {
    event.preventDefault()
    setWritingHomework(event.target.value.trim())
  }

  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }

  const handleAddHomework = async (event) => {
    event.preventDefault()

    if (writingHomework.length === 0 && learningHomework.length === 0) {
      setTextarea1Status('error')
      setTextarea2Status('error')
      setErrorText('Введите описание задания')
      setModalOpen(true)
      return
    }
    if (dateValue == null) {
      setErrorText('Введите дату и время')
      setModalOpen(true)
      return
    }

    console.log(dateValue)

    const year = dateValue.$y
    const month =
      dateValue.$M + 1 < 10 ? `0${dateValue.$M + 1}` : `${dateValue.$M + 1}`
    const day = dateValue.$D < 10 ? `0${dateValue.$D}` : `${dateValue.$D}`

    const timeLine =
      (dateValue.$H < 10 ? `0${dateValue.$H}` : `${dateValue.$H}`) +
      ':' +
      (dateValue.$m < 10 ? `0${dateValue.$m}` : `${dateValue.$m}`) +
      ':00'
    const dateLine = year + '-' + month + '-' + day + 'T' + timeLine + 'Z'

    const hometaskData = {
      article_id: parseInt(articles[articleIndex].id),
      written_description: writingHomework,
      oral_description: learningHomework,
      file_path: '',
      title: '',
      type: '',
      deadline: dateLine,
    }

    console.log(dateLine)

    await postHometask(hometaskData, articleIndex)
    setDateValue(null)
    setErrorText('')
    setTextarea1Status('focused')
    setTextarea2Status('focused')
    setModalOpen(false)
  }

  return (
    <div className='currentHomework'>
      <BackButton />
      {isLoading ? (
        <Spinner size='m' className='loadSpinner' />
      ) : (
        <>
          <Homework
            deadline={hometask ? hometask.deadline : ''}
            writtenDescription={hometask ? hometask.written_description : ''}
            oralDescription={hometask ? hometask.oral_description : ''}
            withSettings={true}
            withArrow={false}
            openSettings={() => {
              openSettings(true)
            }}
          />
          {solutions && hometask && (
            <>
              <div className='usersList'>
                {solutions.map(
                  (solution, index) =>
                    solution.userData && (
                      <User solution={solution} solutionIndex={index} />
                    )
                )}
              </div>
            </>
          )}

          {hometask && (
            <HomeworkSet
              isSettingsOpen={isSettingsOpen}
              openSettings={openSettings}
              updateHometask={updateHometask}
              hometaskIndex={hometaskIndex}
              articleID={hometask.article_id}
              articleIndex={articleIndex}
            />
          )}

          <Modal
            className='addHomework modal'
            isOpen={isModalOpen}
            onClose={closeModal}
          >
            <AppRoot>
              <h3 className='addHomeworkTitle'>Новое задание</h3>
              <Textarea
                onFocus={() => setTextarea1Status('focused')}
                onChange={handleLearningChange}
                header='Устная часть'
                placeholder='Введите задание...'
                status={textarea1Status}
              />
              <Textarea
                onFocus={() => setTextarea2Status('focused')}
                onChange={handleWritingChange}
                header='Письменная часть'
                placeholder='Введите задание...'
                status={textarea2Status}
              />
              <ThemeProvider theme={theme}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale='DE'
                >
                  <DemoContainer components={['DatePicker']}>
                    <MobileDateTimePicker
                      label='Укажите дату'
                      value={dateValue}
                      onChange={(newValue) => setDateValue(newValue)}
                      sx={{
                        width: '60%',
                        marginBottom: '15px !important',
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </ThemeProvider>
              <p
                className='errorText'
                style={{ margin: errorText === '' ? '0' : '10px 0' }}
              >
                {errorText}
              </p>
              <div className='buttons'>
                <Button
                  size='s'
                  mode='plain'
                  onClick={closeModal}
                  className='cancelAddingButton'
                >
                  Отмена
                </Button>
                <Button
                  size='s'
                  mode='filled'
                  onClick={handleAddHomework}
                  className='addHomeworkButton'
                >
                  Добавить задание
                </Button>
              </div>
            </AppRoot>
          </Modal>
          <Box
            onClick={openModal}
            sx={{
              '& > :not(style)': {
                m: 1,
                backgroundColor: 'var(--tgui--button_color)',
              },
              '&': {
                position: 'fixed',
                bottom: '70px',
                right: '15px',
              },
            }}
          >
            <Fab className='' color='primary' aria-label='add'>
              <AddIcon />
            </Fab>
          </Box>
        </>
      )}
    </div>
  )
}

export default CurrentHw
