import React from 'react'
import WritingHw from '../components/WritingHw'
import SpeakHw from '../components/SpeakHw'
import { SlOptionsVertical } from 'react-icons/sl'
import { IoIosArrowRoundForward } from 'react-icons/io'

function Homework({
  deadline,
  writtenDescription,
  oralDescription,
  withSettings,
  openSettings,
  withArrow,
}) {
  let deadlinePretext = ''

  if (writtenDescription + oralDescription === '') {
    deadlinePretext = 'Ничего не задано)'
  } else if (deadline.split('.') === 3) {
    deadlinePretext = 'Дедлайн прошел: ' + deadline
  } else {
    deadlinePretext = 'Срок сдачи: ' + deadline
  }

  return (
    <div className='homework'>
      <div className='settingsAndDeadlineLine'>
        <p className='deadline'>{deadlinePretext}</p>
        {withSettings && oralDescription + writtenDescription != '' ? (
          <SlOptionsVertical
            onClick={() => {
              openSettings()
            }}
            className='settingsIcon'
          />
        ) : (
          withArrow &&
          oralDescription + writtenDescription != '' && (
            <IoIosArrowRoundForward className='arrowIcon' />
          )
        )}
      </div>

      <p className='homeworkText'>
        {writtenDescription !== '' ? (
          <WritingHw text={writtenDescription} />
        ) : (
          ''
        )}
      </p>

      <p className='homeworkText'>
        {oralDescription !== '' ? <SpeakHw text={oralDescription} /> : ''}
      </p>
    </div>
  )
}

export default Homework
