import React from 'react'
import { FaChevronUp } from 'react-icons/fa'
import { FaChevronDown } from 'react-icons/fa6'

function GroupListButton(props) {
  return (
    <div className='groupListButton' onClick={props.onClick}>
      <div className='text'>
        <p className='title'>{props.title}</p>
      </div>
      <FaChevronDown className='icon' />
    </div>
  )
}

export default GroupListButton
