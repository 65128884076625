import { useRouteError } from 'react-router-dom'

function ErrorPage() {
  
  const error = useRouteError()
  console.error(error)

  return (
    <div id='error-page'>
      <h1>Oops!</h1>
      <p>Приносим свои извинения, возникла неизвестная ошибка!</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  )
}

export default ErrorPage
