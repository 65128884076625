import React from 'react'
import { useParams } from 'react-router-dom'
import { FiAlignJustify } from 'react-icons/fi'
import { Link, Outlet } from 'react-router-dom'
import { BsFillPersonFill } from 'react-icons/bs'
import Version from './Version'

function Footer() {
  const { GroupId } = useParams()
  const GroupSetLink = `/Group/GroupSet`
  const GroupLink = `/Group`

  return (
    <div>
      <div className='outletWrap'>
        <Outlet />
      </div>
      <div className='footer'>
        <div className='border'></div>
        <div className='footerCont'>
          <Link to={GroupLink} className='buttonLeft'>
            <FiAlignJustify className='icon' />
            <p className='text'>Дисциплины</p>
          </Link>
          <Link to={GroupSetLink} className='buttonRight'>
            <BsFillPersonFill className='icon' />
            <p className='text'>Группа</p>
          </Link>
        </div>
      </div>
      <Version />
    </div>
  )
}

export default Footer
