import React, { useContext, useEffect, useState } from 'react'
import Homework from '../components/Homework'
import BackButton from '../components/BackButton'
import { Link, useParams } from 'react-router-dom'
import { DataContext } from '../components/DataProvider'
import { Spinner } from '@telegram-apps/telegram-ui'

function Archive() {
  const [isLoading, setLoading] = useState(true)
  const { hometasks, setArticleHometasks } = useContext(DataContext)
  const { articleIndex } = useParams()

  useEffect(() => {
    const fetchHometasks = async () => {
      await setArticleHometasks(articleIndex)
      setLoading(false)
    }

    fetchHometasks()
  }, [])

  return (
    <div className='archive'>
      <BackButton />
      {isLoading ? (
        <Spinner size='m' className='loadSpinner' />
      ) : (
        hometasks[articleIndex] &&
        hometasks[articleIndex].map((hometask, index) => {
          if (index !== 0) {
            const path = `/Group/Archive/${articleIndex}/ArchiveHomework/${index}`
            return (
              <Link to={path}>
                <Homework
                  articleID={hometask.article_id}
                  deadline={hometask.deadline}
                  writtenDescription={hometask.written_description}
                  oralDescription={hometask.oral_description}
                  withArrow={true}
                  addSettings={false}
                />
              </Link>
            )
          }
        })
      )}
    </div>
  )
}

export default Archive
