import React from 'react'
import TextTruncate from 'react-text-truncate'
import { Link } from 'react-router-dom'

function Lesson({ deadline, lessonName, articleIndex }) {
  const path = `/Group/Homework/${articleIndex}/0`

  const isActiveHometask = !(
    deadline === '' || deadline.split('.').length === 3
  )

  return (
    <div className='lessonWrap'>
      <Link to={path}>
        <div className={'lesson'}>
          {deadline && (
            <p
              className={`countHometasks ${
                isActiveHometask ? 'active' : 'passive'
              }`}
            >
              {deadline}
            </p>
          )}
          <p className='lessonName'>
            <TextTruncate
              line={2}
              element='p'
              truncateText='…'
              text={lessonName}
              textTruncateChild=''
            />
          </p>
        </div>
      </Link>
    </div>
  )
}

export default Lesson
