import React from 'react'

function GroupLesson(props) {
  return (
    <div
      className='groupLesson'
      onClick={() => {
        props.setCurrentDeletingId(props.lesson.id)
        props.onClick()
      }}
    >
      <p>{props.lesson.title}</p>
    </div>
  )
}

export default GroupLesson
