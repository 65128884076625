import axios from 'axios'
import React, { createContext, useState, useEffect } from 'react'
import { retrieveLaunchParams } from '@telegram-apps/sdk'
import { Spinner } from '@telegram-apps/telegram-ui'
import { UndoRounded } from '@mui/icons-material'

export const DataContext = createContext()

export const DataProvider = ({ children }) => {
  const [isUserDataLoading, setIsUserDataLoading] = useState(true)
  const [userData, setUserData] = useState(null)
  const [articles, setArticles] = useState(() => {
    const savedArticles = localStorage.getItem('articles')
    return savedArticles ? JSON.parse(savedArticles) : []
  })

  const [group, setGroup] = useState(() => {
    const savedGroup = localStorage.getItem('group')
    return savedGroup ? JSON.parse(savedGroup) : []
  })

  const [hometasks, setHometasks] = useState(() => {
    const savedHometasks = localStorage.getItem('hometasks')
    return savedHometasks ? JSON.parse(savedHometasks) : []
  })

  const [currentArticle, setCurrentArticle] = useState(() => {
    const savedCurrentArticle = localStorage.getItem('currentArticle')
    return savedCurrentArticle ? JSON.parse(savedCurrentArticle) : null
  })

  useEffect(() => {
    const { initDataRaw, initData } = retrieveLaunchParams()
    setUserData(initData.user)
    setIsUserDataLoading(false)
  }, [])

  useEffect(() => {
    if (articles) {
      localStorage.setItem('articles', JSON.stringify(articles))
    }
  }, [articles])

  useEffect(() => {
    if (group) {
      localStorage.setItem('group', JSON.stringify(group))
    }
  }, [group])

  useEffect(() => {
    if (hometasks) {
      localStorage.setItem('hometasks', JSON.stringify(hometasks))
    }
  }, [hometasks])

  useEffect(() => {
    if (currentArticle) {
      localStorage.setItem('currentArticle', JSON.stringify(currentArticle))
    }
  }, [currentArticle])

  const axiosInstance = axios.create({
    baseURL: 'https://lab.easy4.team/api', // Базовый URL для всех запросов
    timeout: 10000, // Таймаут в 10 секунд
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const parseHometask = (hometask) => {
    if (!hometask) {
      return hometask
    }

    const written_description = hometask.written_description
    const oral_description = hometask.oral_description
    const separatedDeadline = hometask.deadline.split('T')[0].split('-')

    const today = new Date()
    const dd = parseInt(String(today.getDate()).padStart(2, '0'))
    const mm = parseInt(String(today.getMonth() + 1).padStart(2, '0'))
    const yy = parseInt(String(today.getYear()).padStart(2, '0'))

    const deadlineDay = parseInt(separatedDeadline[2])
    const deadlineMonth = parseInt(separatedDeadline[1])
    const deadlineYear = parseInt(separatedDeadline[0])

    let deadline

    if (
      deadlineYear < yy ||
      (deadlineYear == yy && deadlineMonth < mm) ||
      (deadlineYear == yy && deadlineMonth === mm && deadlineDay < dd)
    ) {
      deadline =
        String(deadlineDay) +
        '.' +
        String(deadlineMonth) +
        '.' +
        String(deadlineYear)
    } else {
      deadline = String(deadlineDay) + '.' + String(deadlineMonth)
    }

    const id = hometask.id
    console.log('Hometask deadline: ', deadline)
    return {
      written_description,
      oral_description,
      deadline,
      id,
    }
  }

  const getArticleHometasks = async (articleId) => {
    try {
      const response = await axiosInstance.get(
        `/hometask/get_all?article_id=${articleId}`
      )

      const result = response.data.map((hometask) => parseHometask(hometask))
      return result
    } catch (error) {
      console.error('Error on getting hometasks: ', error)
    }
  }

  const getCurrentArticleHometask = async (articleIndex) => {
    try {
      const response = await axiosInstance.get(
        `/hometask/get_closest?article_id=${articles[articleIndex].id}`
      )
      return response.data
    } catch (error) {
      if (error.status != 500) {
        console.error('Error getting current article hometask: ', error)
      } else {
        return undefined
      }
    }
  }

  const getHometaskSolutions = async (hometaskId) => {
    try {
      const response = await axiosInstance.get(
        `/solution/get_all?hometask_id=${hometaskId}`
      )
      const solutions = response.data
      console.log(solutions)
      const solutionsWithUser = await Promise.all(
        solutions.map(async (solution) => {
          const userData = await getUserById(solution.user_id)
          solution.userData = userData
          return solution
        })
      )
      console.log('solutions with user: ', solutionsWithUser)

      return solutionsWithUser
    } catch (error) {
      console.error('Error on getting answer: ', error)
      return
    }
  }

  const getUserById = async (userID = userData.id) => {
    try {
      const response = await axiosInstance.get(`/user/get?id=${userID}`)
      return response.data
    } catch (error) {
      console.error('Error on getting user by id: ', error.response)
    }
  }

  const updateData = async () => {
    try {
      const response = await axiosInstance.get(
        `/article/get_all?group_id=${group.id}`
      )

      setArticles(response.data)
      await setCurrentHometasks()
    } catch (error) {
      console.error('Error updating data: ', error)
    }
  }

  const updateHometasks = async (articleIndex) => {
    try {
      const currentHometask = await getCurrentArticleHometask(articleIndex)
      const archiveHometasks = getArticleHometasks(articleIndex).filter(
        (hometask) => hometask.id != currentHometask.id
      )

      setHometasks((prevHometasks) => {
        const newHometasks = [...prevHometasks]
        newHometasks[articleIndex] = [currentHometask, ...archiveHometasks]

        return newHometasks
      })
    } catch (error) {
      console.error('Error on updating hometasks after adding: ', error)
    }
  }

  const updateHometask = async (articleIndex, data) => {
    try {
      const response = await axiosInstance.post(
        `/hometask/update?hometask_id=${hometasks[articleIndex].id}`,
        data
      )
      const updatedArticleHometasks = await getArticleHometasks(articleIndex)

      setHometasks((prev) => {
        const newHometasks = [...prev]
        newHometasks[articleIndex] = updatedArticleHometasks
          ? updatedArticleHometasks
          : []
        return newHometasks
      })

      return response.data
    } catch (error) {
      console.error('Error on updating hometask: ', error)
    }
  }

  const setArticleHometasks = async (articleIndex) => {
    if (!articles[articleIndex] || hometasks[articleIndex][0] === undefined) {
      return
    }

    const article = articles[articleIndex]
    const archiveHometasks = await getArticleHometasks(article.id)
    const resultHometasks = archiveHometasks.filter(
      (hometask) => hometask.id != hometasks[articleIndex][0].id
    )

    if (!resultHometasks) {
      return
    }

    setHometasks((prevHometasks) => {
      const newHometasks = [...prevHometasks]

      newHometasks[articleIndex] = [
        ...newHometasks[articleIndex],
        ...resultHometasks,
      ]

      return newHometasks
    })
  }

  const setData = async (groupId) => {
    try {
      const response = await axiosInstance.get(`/group/get?group_id=${groupId}`)
      setGroup(response.data)
      await setAllArticles(groupId)
      return response.data
    } catch (error) {
      console.error('Error getting groupInfo: ', error)
    }
  }

  const setCurrentHometasks = async () => {
    const allCurrentHometasks = []

    for (let i = 0; i < articles.length; i++) {
      const currentHometask = await getCurrentArticleHometask(i)
      const parsedHometask = parseHometask(currentHometask)
      allCurrentHometasks.push(parsedHometask ? [parsedHometask] : [])
    }

    setHometasks(allCurrentHometasks)
  }

  const setAllArticles = async (groupId) => {
    try {
      const response = await axiosInstance.get(
        `/article/get_all?group_id=${groupId}`
      )
      setArticles(response.data)
      await setCurrentHometasks()
    } catch (error) {
      console.error('Error on getting lessons: ', error)
    }
  }

  const setHometaskSolutions = async (articleIndex, hometaskIndex) => {
    try {
      const solutions = await getHometaskSolutions(
        hometasks[articleIndex][hometaskIndex].id
      )
      hometasks[articleIndex][hometaskIndex].solutions = solutions
    } catch (error) {
      console.error('Error on setting hometask solutions: ', error)
    }
  }

  const deleteArticle = async (lessonId) => {
    try {
      const response = await axiosInstance.delete(
        `/article/delete?article_id=${lessonId}`
      )
      await updateData()

      return response.data
    } catch (error) {
      console.error('Error on deleting lesson: ', error)
    }
  }

  const deleteUser = async () => {
    try {
      const response = await axiosInstance.delete(
        `/user/delete?id=${userData.id}`
      )
      return response.data
    } catch (error) {
      console.error('Error on deleting user: ', error)
    }
  }

  const deleteFromGroup = async () => {
    try {
      const response = await axiosInstance.delete(
        `/group/leave_group?id=${userData.id}`
      )
      return response.data
    } catch (error) {
      console.error('Error on leaving group: ', error)
    }
  }

  const postGroup = async (data) => {
    try {
      const response = await axiosInstance.post(
        `/group/add?id=${userData.id}`,
        {
          group_name: data,
        }
      )
      return response.data.groupID
    } catch (error) {
      console.error('Error posting group:', error.response)
    }
  }

  const postUser = async (data) => {
    try {
      const response = await axiosInstance.post(`/user/add`, data)
      return response.data
    } catch (error) {
      console.error('Error posting user: ', error)
    }
  }

  const postUserToGroup = async (groupID) => {
    const userGroupData = {
      group_id: groupID,
      role_id: 1,
      user_id: userData.id,
    }

    try {
      const response = await axiosInstance.post(
        `/group/add_to_group`,
        userGroupData
      )
      return response.data
    } catch (error) {
      console.error('Error adding user to group: ', error)
    }
  }

  const postHometask = async (data, articleIndex) => {
    try {
      const response = await axiosInstance.post('/hometask/add', data)
      await updateHometasks(articleIndex)

      return response
    } catch (error) {
      throw error
    }
  }

  const postLesson = async (title) => {
    try {
      const response = await axiosInstance.post('/article/add', {
        group_id: group.id,
        title: title,
      })

      await updateData()
      return response.data
    } catch (error) {
      console.error('Error on posting lesson: ', error)
    }
  }

  const getGroupByLink = async (link) => {
    try {
      const response = await axiosInstance.get(
        `/group/get_by_link?link=${link}`
      )
      return response.data.groupID
    } catch (error) {
      throw error
    }
  }

  const getGroupByUserID = async () => {
    try {
      const response = await axiosInstance.get(
        `/group/get_by_user_id?id=${userData.id}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  const getArticle = async (articleID) => {
    try {
      const response = await axiosInstance.get(
        `article/get?article_id=${articleID}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  return (
    <DataContext.Provider
      value={{
        articles,
        group,
        hometasks,
        currentArticle,
        userData,
        postGroup,
        postUser,
        postLesson,
        postHometask,
        postUserToGroup,
        setCurrentHometasks,
        setHometaskSolutions,
        setArticleHometasks,
        getUserById,
        getGroupByUserID,
        getArticle,
        getGroupByLink,
        updateData,
        deleteArticle,
        deleteFromGroup,
        deleteUser,
        setData,
        updateHometask,
      }}
    >
      {isUserDataLoading ? <Spinner className='loadSpinner' /> : children}
    </DataContext.Provider>
  )
}
