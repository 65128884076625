import React, { useState } from 'react'
import Modal from './Modal'
import { useParams } from 'react-router-dom'
import { AppRoot, Textarea, Button } from '@telegram-apps/telegram-ui'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'
import { CssBaseline } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

export default function HomeworkSet(props) {
  const [oralHomework, setOralHomework] = useState('')
  const [writingHomework, setWritingHomework] = useState('')
  const [textarea1Status, setTextarea1Status] = useState('focused')
  const [textarea2Status, setTextarea2Status] = useState('focused')
  const [errorText, setErrorText] = useState('')
  const [dateValue, setDateValue] = useState(null)

  const theme = document.querySelector('.darkBody')
    ? createTheme({
        palette: {
          mode: 'dark',
        },
      })
    : createTheme({
        palette: {
          mode: 'light',
        },
      })

  const handleOralChange = (event) => {
    event.preventDefault()
    setOralHomework(event.target.value.trim())
  }

  const handleWritingChange = (event) => {
    event.preventDefault()
    setWritingHomework(event.target.value.trim())
  }

  const handleUpdateHometask = async (event) => {
    event.preventDefault()

    if (writingHomework.length === 0 && oralHomework.length === 0) {
      setTextarea1Status('error')
      setTextarea2Status('error')
      setErrorText('Введите описание задания')
      props.openSettings(true)
      return
    }
    if (dateValue == null) {
      setErrorText('Введите дату и время')
      props.openSettings(true)
      return
    }

    const year = dateValue.$y
    const month =
      dateValue.$M + 1 < 10 ? `0${dateValue.$M + 1}` : `${dateValue.$M + 1}`
    const day = dateValue.$D < 10 ? `0${dateValue.$D}` : `${dateValue.$D}`

    const timeLine =
      (dateValue.$H < 10 ? `0${dateValue.$H}` : `${dateValue.$H}`) +
      ':' +
      (dateValue.$m < 10 ? `0${dateValue.$m}` : `${dateValue.$m}`) +
      ':00'
    const dateLine = year + '-' + month + '-' + day + 'T' + timeLine + 'Z'

    const hometaskData = {
      article_id: parseInt(props.articleID),
      written_description: writingHomework,
      oral_description: oralHomework,
      file_path: '',
      title: '',
      deadline: dateLine,
    }

    await props.updateHometask(parseInt(props.articleIndex), hometaskData)
    setDateValue(null)
    setErrorText('')
    setTextarea1Status('focused')
    setTextarea2Status('focused')
    props.openSettings(false)
  }

  return (
    <Modal
      className='setHomework modal'
      isOpen={props.isSettingsOpen}
      onClose={() => {
        props.openSettings(false)
      }}
    >
      <AppRoot>
        <h3 className='setHomeworkTitle'>Изменить задание</h3>
        <Textarea
          onFocus={() => setTextarea1Status('focused')}
          onChange={handleOralChange}
          header='Устная часть'
          placeholder='Введите задание...'
          status={textarea1Status}
        />
        <Textarea
          onFocus={() => setTextarea2Status('focused')}
          onChange={handleWritingChange}
          header='Письменная часть'
          placeholder='Введите задание...'
          status={textarea2Status}
        />
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='DE'>
            <DemoContainer components={['DatePicker']}>
              <MobileDateTimePicker
                label='Укажите дату'
                value={dateValue}
                onChange={(newValue) => setDateValue(newValue)}
                sx={{
                  width: '60%',
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </ThemeProvider>
        <p
          className='errorText'
          style={{ margin: errorText === '' ? '0' : '10px 0' }}
        >
          {errorText}
        </p>
        <div className='buttons'>
          <Button
            size='s'
            mode='plain'
            onClick={() => {
              props.openSettings(false)
            }}
            className='cancelAddingButton'
          >
            Отмена
          </Button>
          <Button
            size='s'
            mode='filled'
            onClick={handleUpdateHometask}
            className='addHomeworkButton'
          >
            Изменить задание
          </Button>
        </div>
      </AppRoot>
    </Modal>
  )
}
